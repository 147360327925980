<template>
  <div class="product-zone">
    <div class="product-zone__wrapper">
      <div class="zone-title">
        <div class="main-title">产品展示</div>
        <div class="tip-title">Product Center</div>
        <div class="more">
          <a href="javascript:void(0)" @click="$router.push('/product')">查看更多</a>
        </div>
      </div>
      <div class="product-list">
        <div class="product" v-for="item of recommendList" :key="item.id">
          <img :src="`${$host}:7001/public/${item.productPic}`" alt="NO IMAGE">
          <div class="product-name">{{ item.productName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      recommendList: Array
    },
    methods: {
    
    }
  }
</script>

<style lang="less">
  @media screen and (max-width: 1500px) {
    .product-zone {
      &__wrapper {
        width: 59% !important;
  
        .product-list .product img {
          width: 177px !important;
          height: 137px !important;
        }
      }
    }
  }
  
  .product-zone {
    background: #ffffff;
    width: 100%;
    padding: 32px 0;
    
    &__wrapper {
      margin: 0 auto;
      width: 1300px;
      
      .zone-title {
        position: relative;
        .more {
          position: absolute;
          right: 0;
          top: 50%;
          a {
            color: #333333;
          }
        }
        
        .main-title {
          border-bottom: 1px solid #bcbcbc;
          color: #2256a8;
          font-weight: 600;
          font-size: 36px;
          margin: 0 auto;
          position: relative;
          padding: 16px 8px;
          text-align: center;
          width: 500px;
          
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 50px;
            height: 3px;
            background: #2256a8;
          }
        }
        
        .tip-title {
          margin: 0 auto;
          width: 200px;
          text-align: center;
          padding: 8px;
        }
      }
      
      .product-list {
        display: flex;
        justify-content: space-between;
        /*flex-wrap: wrap;*/
        margin: 48px 0;
        .product {
          border: 1px solid #d8d8d8;
          display: flex;
          flex-direction: column;
          text-align: center;
          cursor: pointer;
          margin: 4px 4px;

          &:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
          }
          
          img {
            margin-bottom: 16px;
            width: 227px;
            height: 198px;
          }
          
          .product-name {
            background-color: #2256a8;
            color: #ffffff;
            padding: 16px;
            flex-grow: 1;
          }
        }
      }
    }
  }
</style>
