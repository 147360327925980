<template>
  <div class="scroll-image">
    <el-carousel v-if="scrollHeight" trigger="click" :height="scrollHeight">
      <el-carousel-item v-for="pic of list" :key="pic.id">
        <img :src="`${$host}:7001/public/${pic.content}`" alt="NO IMAGE" style="width: 100%;">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  export default {
    props: {
      list: Array
    },
    data() {
      return {
        scrollHeight: '510px',
      }
    },
    mounted() {
      setTimeout(() => {
        const img = document.querySelector('.scroll-image img:first-child')
        if (img) {
          this.scrollHeight = img.offsetHeight + 'px';
        }
        window.onresize = () => {
          const img = document.querySelector('.scroll-image img:first-child')
          if (img) {
            this.scrollHeight = img.offsetHeight + 'px';
          }
        }
      }, 100)
    },
    methods: {
    
    }
  }
</script>

<style lang="less">
  .scroll-image {
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }
  
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }
  
    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }
  }
</style>
