<template>
  <div class="news-zone">
    <div class="news-zone__wrapper">
      <div class="zone-title">
        <div class="main-title">新闻中心</div>
        <div class="tip-title">News Center</div>
        <div class="more">
          <a href="javascript:void(0)" @click="$router.push('/mobile/news')">查看更多</a>
        </div>
      </div>
      <div class="news-list">
        <div class="right">
          <div class="news-item" v-for="item of newsList" :key="item.id">
            <div class="date">
              <div class="day">{{item.date.slice(8)}}</div>
              <div class="month">{{item.date.slice(2, 7)}}</div>
            </div>
            <div class="msg">
              <div class="title">{{ item.title }}</div>
              <div class="detail">{{ item.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        newsList: []
      }
    },
    mounted() {
      this.getNewsList()
    },
    methods: {
      getNewsList() {
        axios.get('/api/news/').then(data => {
          this.newsList = data.data.slice(0, 3)
        })
      }
    }
  }
</script>
<style lang="less">
  .mobile {
    .news-zone {
      background: #ffffff;
      width: 100%;
      padding: 16px 0;
    
      &__wrapper {
        margin: 0 auto;
        width: 100%;
        padding: 0;
      
        .zone-title {
          .more {
            position: absolute;
            right: 12px;
            top: 38%;
            font-size: 14px;
            a {
              color: #333333;
            }
          }
          .main-title {
            border-bottom: 1px solid #bcbcbc;
            color: #2256a8;
            font-weight: 600;
            font-size: 24px;
            margin: 0 auto;
            position: relative;
            padding: 16px 8px;
            text-align: center;
            width: 65%;
          
            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
              width: 50px;
              height: 3px;
              background: #2256a8;
            }
          }
        
          .tip-title {
            margin: 0 auto;
            width: 200px;
            text-align: center;
            padding: 8px;
          }
        }
      
        .news-list {
          display: flex;
          margin: 24px 0;
          padding: 0 16px;
        
          .left {
          }
        
          .right {
            width: 100%;
            margin-left: 0;
          
            & > .news-item:not(:first-child) {
              margin-top: 8px;
            }
  
            .news-item {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #d3d3d3;
              padding-bottom: 6px;
              margin: 8px 0;
              cursor: pointer;
              &.active {
                .detail {
                  white-space: normal;
                }
              }
    
              .date {
                color: #666666;
                text-align: center;
                margin: 3px 12px 3px 3px;
                .day {
                  font-size: 20px;
                  font-weight: 600;
                  margin-bottom: 4px;
                }
                .month {
                  white-space: nowrap;
                  font-size: 14px;
                }
              }
            }
  
            .msg {
              color: #333333;
              width: calc(100% - 64px - 48px);
              .title {
                font-size: 16px;
                letter-spacing: 3px;
                padding: 2px 0;
                font-weight: 600;
              }
              .detail {
                font-size: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }
</style>
