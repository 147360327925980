<template>
  <div class="about-us">
    <div class="about-us__wrapper">
      <div class="right">
        <div class="title">关于我们</div>
        <div class="tip">About Us</div>
        <div class="line"></div>
        <div class="msg">
          <span>{{message}}</span>
        </div>
        <button class="more" @click="$router.push('/mobile/about')">
          查看更多 >>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        message: ''
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.message = data.data.about
        })
      }
    }
  }
</script>
<style lang="less">
  .mobile {
    .about-us {
      background: #cfdae5;
      width: 100%;
      padding: 16px 0;
    
      &__wrapper {
        margin: 0 auto;
        width: 100% !important;
        display: flex;
      
        .left {
          width: 40%;
          img {
            transform: translate(-80px, -55px);
          }
        }
      
        .right {
          width: 100%;
          padding: 16px;
        
          .title {
            font-size: 20px;
            color: #2256a8;
            font-weight: 600;
            letter-spacing: 2px;
          }
        
          .tip {
            margin-top: 6px;
          }
        
          .line {
            width: 50px;
            height: 15px;
            background: transparent;
            border-bottom: 3px solid #333333;
          }
        
          .msg {
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 20px;
          }
        
          .more {
            background-color: #2256a8;
            color: #ffffff;
            border: 0;
            outline: 0;
            padding: 10px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
