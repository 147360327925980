<template>
  <div class="nav-menu">
    <div class="nav-menu__wrapper">
      <div
        :class="['menu-item', menu.id === activeId ? 'active' : '']"
        v-for="menu of menus"
        :key="menu.id"
        @click="handleClick(menu.id, menu.to)"
      >
        {{ menu.title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menus: [
          { id: 1, title: '首页', to: '/' },
          { id: 2, title: '产品展示', to: '/product' },
          { id: 3, title: '维修服务及改造', to: '/fix' },
          { id: 4, title: '关于我们', to: '/about' },
          { id: 5, title: '新闻中心', to: '/news' },
          { id: 6, title: '联系我们', to: '/contact' },
          { id: 7, title: '人才招聘', to: '/recruit' }
        ]
      }
    },
    
    computed: {
      activeId() {
        const { path } = this.$route
        return this.menus.filter((item) => item.to === path)[0].id
      }
    },
    
    methods: {
      handleClick(activeId, to) {
        // this.activeId = activeId
        const path = this.$route.path
        if (path !== to) {
          this.$router.push(to)
        }
      }
    }
  }
</script>

<style lang="less">
  @media screen and (max-width: 1500px) {
    .nav-menu {
      &__wrapper {
        width: 59% !important;
      }
    }
  }
  
  .nav-menu {
    background: url("../../assets/png/nav-menu-bg.png") no-repeat;
    height: 60px;
    width: 100%;
    
    &__wrapper {
      height: 100%;
      margin: 0 auto;
      width: 1300px;
  
      .menu-item {
        color: #ffffff;
        display: inline-block;
        height: 60px;
        font-size: 16px;
        line-height: 60px;
        text-align: center;
        width: calc(100% / 7);
        cursor: pointer;
        
        &.active,
        &:hover {
          box-sizing: border-box;
          border-bottom: 4px solid #ffa202;
          color: #ffa102;
        }
      }
    }
  }
</style>
