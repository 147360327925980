<template>
  <div class="news-zone">
    <div class="news-zone__wrapper">
      <div class="zone-title">
        <div class="main-title">新闻中心</div>
        <div class="tip-title">News Center</div>
        <div class="more">
          <a href="javascript:void(0)" @click="$router.push('/news')">查看更多</a>
        </div>
      </div>
      <div class="news-list">
        <div class="left">
          <img src="@/assets/png/news-1.jpg" alt="NO IMAGE">
        </div>
        <div class="right">
          <div class="news-item" v-for="item of newsList" :key="item.id">
            <div class="date">
              <div class="day">{{item.date.slice(8)}}</div>
              <div class="month">{{item.date.slice(2, 7)}}</div>
            </div>
            <div class="msg">
              <div class="title">{{ item.title }}</div>
              <div class="detail">{{ item.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        newsList: []
      }
    },
    mounted() {
      this.getNewsList()
    },
    methods: {
      getNewsList() {
        axios.get('/api/news/').then(data => {
          this.newsList = data.data.slice(0, 3)
        })
      }
    }
  }
</script>

<style lang="less">
  .news-zone {
    background: #ffffff;
    width: 100%;
    padding: 32px 0;
    
    &__wrapper {
      margin: 0 auto;
      width: 1300px;
      padding: 0 90px;
  
      .zone-title {
        position: relative;
        .more {
          position: absolute;
          right: 0;
          top: 50%;
          a {
            color: #333333;
          }
        }
        .main-title {
          border-bottom: 1px solid #bcbcbc;
          color: #2256a8;
          font-weight: 600;
          font-size: 36px;
          margin: 0 auto;
          position: relative;
          padding: 16px 8px;
          text-align: center;
          width: 500px;
      
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 50px;
            height: 3px;
            background: #2256a8;
          }
        }
    
        .tip-title {
          margin: 0 auto;
          width: 200px;
          text-align: center;
          padding: 8px;
        }
      }
      
      .news-list {
        margin: 48px 0;
        display: flex;
        
        .left {
        }
        
        .right {
          margin-left: 96px;
          width: calc(100% - 445px - 96px);
          
          & > .news-item:not(:first-child) {
            margin-top: 8px;
          }
          
          .news-item {
            display: flex;
            border-bottom: 1px solid #d3d3d3;
            padding-bottom: 12px;
            
            .date {
              color: #666666;
              text-align: center;
              margin: 3px 48px 3px 0;
              .day {
                font-size: 30px;
                font-weight: 600;
              }
              .month {
                white-space: nowrap;
                font-size: 18px;
              }
            }
          }
          
          .msg {
            color: #333333;
            width: calc(100% - 64px - 48px);
            .title {
              font-size: 18px;
              letter-spacing: 3px;
              padding: 8px 0;
              font-weight: 600;
            }
            .detail {
              font-size: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
</style>
