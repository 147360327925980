<template>
  <div class="footer" v-if="companyInfo">
    <div>
      <span>Copyright&copy; {{companyInfo.copyright}}</span>
      <span style="margin-left: 8px;" v-if="companyInfo.designedBy">Designed by {{companyInfo.designedBy}}</span>
      <span style="margin-left: 8px;">{{companyInfo.companyName}}</span>
      <span style="margin-left: 8px;">版权所有</span>
    </div>
    <div>
      <span>地址：{{companyInfo.address}}</span>
      <span style="margin-left: 8px;" v-if="companyInfo.telephone">电话：{{companyInfo.telephone}}</span>
      <span style="margin-left: 8px;" v-if="companyInfo.qq">QQ：{{companyInfo.qq}}</span>
      <span style="margin-left: 8px;" v-if="companyInfo.wx">微信号：{{companyInfo.wx}}</span>
      <span style="margin-left: 8px;" v-if="companyInfo.email">邮箱：{{companyInfo.email}}</span>
    </div>
    <div style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="http://www.beian.gov.cn/img/new/gongan.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">豫ICP备2020035508号-1</p ></a>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        companyInfo: null
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.companyInfo = data.data
        })
      }
    }
  }
</script>

<style lang="less">
  .footer {
    width: 100%;
    background: #2b3036;
    color: #ffffff;
    text-align: center;
    padding: 48px 0;
    
    div {
      padding: 8px 0;
    }
  }
</style>
