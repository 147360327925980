<template>
  <div class="product-zone">
    <div class="product-zone__wrapper">
      <div class="zone-title">
        <div class="main-title">产品展示</div>
        <div class="tip-title">Product Center</div>
        <div class="more">
          <a href="javascript:void(0)" @click="$router.push('/mobile/product')">查看更多</a>
        </div>
      </div>
      <div class="product-list">
        <div class="product" v-for="item of recommendList.slice(0, 4)" :key="item.id">
          <img :src="`${$host}:7001/public/${item.productPic}`" alt="NO IMAGE">
          <div class="product-name">{{ item.productName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      recommendList: Array
    },
    methods: {
    
    }
  }
</script>
<style lang="less">
  .mobile {
    .product-zone {
      background: #ffffff;
      width: 100%;
      padding: 16px 0;
    
      &__wrapper {
        margin: 0 auto;
        width: 100% !important;
      
        .zone-title {
          .more {
            position: absolute;
            right: 12px;
            top: 38%;
            font-size: 14px;
            a {
              color: #333333;
            }
          }
          .main-title {
            border-bottom: 1px solid #bcbcbc;
            color: #2256a8;
            font-weight: 600;
            font-size: 24px;
            margin: 0 auto;
            position: relative;
            padding: 16px 8px;
            text-align: center;
            width: 65%;
          
            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
              width: 50px;
              height: 3px;
              background: #2256a8;
            }
          }
        
          .tip-title {
            margin: 0 auto;
            width: 200px;
            text-align: center;
            padding: 8px;
          }
        }
      
        .product-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 24px 8px;
          .product {
            border: 1px solid #d8d8d8;
            text-align: center;
            cursor: pointer;
            width: calc((100% - 8px) / 2);
            display: flex;
            flex-direction: column;
            margin: 8px 0;
          
            &:hover {
              box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
            }
          
            img {
              margin-bottom: 16px;
              height: 120px !important;
              width: 100% !important;
            }
          
            .product-name {
              background-color: #2256a8;
              color: #ffffff;
              padding: 8px;
              font-size: 14px;
              flex-grow: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
</style>
