<template>
  <div class="basic-layout" v-if="!isMobile">
    <company-contact />
    <nav-menu />
    <scroll-image v-if="!pagePic" :list="picList"/>
    <img v-if="pagePic" :src="pagePic" alt="NO IMAGE" width="100%">
    <template v-if="isHome">
      <product-zone :recommend-list="recommendList" />
      <apply-zone />
      <about-us />
      <news-zone />
    </template>
    <template v-else>
      <router-view />
    </template>
    <company-footer />
  </div>
  <div class="basic-layout mobile" v-else>
    <div class="telephone">
      <img src="@/assets/png/telephone-mobile.png" alt="NO IMAGE">
    </div>
    <div class="logo">
      <img src="@/assets/png/logo-mobile.png" alt="NO IMAGE">
    </div>
    <nav-menu-mobile />
    <template v-if="isHome">
      <scroll-image-mobile v-if="!pagePic" :list="picList"/>
      <product-zone-mobile :recommend-list="recommendList" />
      <apply-zone-mobile />
      <about-us-mobile />
      <news-zone-mobile />
    </template>
    <template v-else>
      <router-view />
    </template>
    <company-footer-mobile />
  </div>
</template>

<script>
  import CompanyContact from '@/components/CompanyContact'
  import NavMenu from '@/components/NavMenu'
  import ScrollImage from '@/components/ScrollImage'
  import ProductZone from '@/components/ProductZone'
  import ApplyZone from '@/components/ApplyZone'
  import AboutUs from '@/components/AboutUs'
  import NewsZone from '@/components/NewsZone'
  import CompanyFooter from '@/components/CompanyFooter'
  import ProductImg from '@/assets/png/scroll-1.jpg'
  import FixImg from '@/assets/png/fix.jpg'
  import AboutImg from '@/assets/png/about.jpg'
  import NewsImg from '@/assets/png/news.jpg'
  import ContactImg from '@/assets/png/contact.jpg'
  import RecruitImg from '@/assets/png/recruit.jpg'
  import NavMenuMobile from '@/components/NavMenuMobile'
  import ScrollImageMobile from '@/components/ScrollImageMobile'
  import ProductZoneMobile from '@/components/ProductZoneMobile'
  import ApplyZoneMobile from '@/components/ApplyZoneMobile'
  import AboutUsMobile from '@/components/AboutUsMobile'
  import NewsZoneMobile from '@/components/NewsZoneMobile'
  import CompanyFooterMobile from '@/components/CompanyFooterMobile'
  import axios from 'axios'
  
  export default {
    components: {
      CompanyContact,
      NavMenu,
      ScrollImage,
      ProductZone,
      ApplyZone,
      AboutUs,
      NewsZone,
      CompanyFooter,
      NavMenuMobile,
      ScrollImageMobile,
      ProductZoneMobile,
      ApplyZoneMobile,
      AboutUsMobile,
      NewsZoneMobile,
      CompanyFooterMobile
    },
    
    data() {
      return {
        isMobile: false,
        picList: [],
        productList: [],
      }
    },
    
    computed: {
      isHome() {
        const path = this.$route.path
        return path === '/'
      },
      pagePic() {
        const path = this.$route.path
        let pic = null;
        switch(path) {
          case '/product':
            pic = ProductImg
            break
          case '/fix':
            pic = FixImg
            break
          case '/about':
            pic = AboutImg
            break
          case '/news':
            pic = NewsImg
            break
          case '/contact':
            pic = ContactImg
            break
          case '/recruit':
            pic = RecruitImg
            break
          default:
            pic = null
        }
        return pic
      },
      recommendList() {
        return this.productList.filter((obj) => !!obj.isRecommend)
      }
    },
    
    mounted() {
      const body = document.querySelector('body')
      this.isMobile = body.offsetWidth <= 750
      this.getScrollList()
      this.getProductList()
      /*const path = this.$route.path
      console.log(path)
      if (this.isMobile && path.indexOf('mobile') < 0) {
        this.$router.push('/mobile' + path)
      }
      if (!this.isMobile && path.indexOf('mobile') > -1) {
        this.$router.push(path.slice(7))
      }*/
    },
    
    methods: {
      getScrollList() {
        axios.get('/api/picture').then(data => {
          this.picList = data.data
        })
      },
      getProductList() {
        axios.get('/api/product').then(data => {
          this.productList = data.data
        })
      }
    }
  }
</script>

<style lang="less">
  .basic-layout {
  }
  
  .basic-layout.mobile {
    .telephone {
      img {
        width: 100%;
      }
    }
    .logo {
      text-align: center;
      img {
        width: 70%;
      }
    }
  }
</style>
