<template>
  <div class="nav-menu-mobile">
    <div class="nav-menu-mobile__wrapper">
      <div
        :class="['menu-item', menu.id === activeId ? 'active' : '']"
        v-for="menu of menus"
        :key="menu.id"
        @click="handleClick(menu.id, menu.to)"
      >
        {{ menu.title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menus: [
          { id: 1, title: '首页', to: '/' },
          { id: 2, title: '产品展示', to: '/mobile/product' },
          { id: 3, title: '维修服务', to: '/mobile/fix' },
          { id: 4, title: '关于我们', to: '/mobile/about' },
          { id: 5, title: '新闻中心', to: '/mobile/news' },
          { id: 6, title: '联系我们', to: '/mobile/contact' },
          { id: 7, title: '人才招聘', to: '/mobile/recruit' }
        ]
      }
    },
  
    computed: {
      activeId() {
        const { path } = this.$route
        return this.menus.filter((item) => item.to === path)[0].id
      }
    },
    
    methods: {
      handleClick(activeId, to) {
        const path = this.$route.path
        if (path !== to) {
          this.$router.push(to)
        }
      }
    }
  }
</script>

<style lang="less">
  .mobile {
    .nav-menu-mobile {
      width: 100%;
      margin: 8px 0;
    
      &__wrapper {
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
      
        .menu-item {
          color: #ffffff;
          display: inline-block;
          margin: 4px;
          height: 36px;
          font-size: 14px;
          line-height: 36px;
          text-align: center;
          width: calc((100% - 24px) / 4);
          background-color: #2256a8;
        
          &:nth-child(1) {
            margin-left: 0;
          }
        
          &:nth-child(4) {
            margin-right: 0;
          }
        
          &:nth-child(5) {
            margin-left: 0;
          }
        
          &.active,
          &:hover {
            box-sizing: border-box;
            border-bottom: 4px solid #ffa202;
            color: #ffa102;
          }
        }
      }
    }
  }
</style>
