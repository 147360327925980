<template>
  <div class="about-us">
    <div class="about-us__wrapper">
      <div class="left">
        <img src="@/assets/png/about-us.png" alt="NO IMAGE">
      </div>
      <div class="right">
        <div class="title">关于我们</div>
        <div class="tip">About Us</div>
        <div class="line"></div>
        <div class="msg">
          <pre>{{message}}</pre>
        </div>
        <button class="more" @click="$router.push('/about')">
          查看更多 >>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        message: ''
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.message = data.data.about
        })
      }
    }
  }
</script>

<style lang="less">
  @media screen and (max-width: 1500px) {
    .about-us {
      
      &__wrapper {
        width: 59% !important;
        
        .left {
          img {
            transform: translate(-167px, -84px) scale(0.8) !important;
          }
        }
        
        .right {
          .msg {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  
  .about-us {
    background: #cfdae5;
    width: 100%;
    padding: 32px 0;
    
    &__wrapper {
      margin: 0 auto;
      width: 1300px;
      display: flex;
      
      .left {
        width: 40%;
        img {
          transform: translate(-80px, -55px);
        }
      }
      
      .right {
        width: 60%;
        padding: 32px 32px 32px 64px;
        
        .title {
          font-size: 24px;
          color: #2256a8;
          font-weight: 600;
          letter-spacing: 2px;
        }
        
        .tip {
          margin-top: 6px;
        }
        
        .line {
          width: 50px;
          height: 30px;
          background: transparent;
          border-bottom: 3px solid #333333;
        }
        
        .msg {
          font-size: 18px;
          letter-spacing: 3px;
          line-height: 30px;
          overflow-wrap: anywhere;
        }
        
        .more {
          background-color: #2256a8;
          color: #ffffff;
          border: 0;
          outline: 0;
          padding: 12px 16px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
</style>
